<template>
  <ribbon
    v-if="!isLoading && !!state"
    class="has-background-black has-text-white"
    padding="none"
  >
    <div id="site-subscription">
      <p>
        <b-icon
          v-if="$_.has(state, 'icon')"
          :icon="state.icon"
          :type="`is-${state.type}`"
          size="is-small"
        />
        {{ state.text }}
      </p>
      <p v-if="state.cta && ['owner', 'admin', 'agent'].includes(userRole)">
        <button
          :disabled="isProcessing"
          :class="{ [`is-${state.type}`]: true, 'is-loading': isProcessing }"
          type="button"
          class="button is-rounded"
          @click="state.cta.action()"
        >
          {{ state.cta.label }}
        </button>
      </p>
    </div>
  </ribbon>
</template>

<script>
export default {
  name: "SiteSubscription",
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      contract: null
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    userRole() {
      return this.$store.getters["sites/site/userRole"](this.siteId);
    },
    daysRemaining() {
      return this.$store.getters["billing/contract/daysRemaining"](
        this.contract
      );
    },
    termLength() {
      return this.$store.getters["billing/contract/termLength"](this.contract);
    },
    dueDate() {
      return this.$store.getters["billing/contract/dueDate"](this.contract);
    },
    status() {
      return this.$store.getters["billing/contract/status"](this.contract);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    state() {
      switch (`${this.status}-${this.userRole}`) {
        case "none-owner":
        case "none-admin":
        case "none-agent":
          return {
            text: `There is no active subscription for ${this.site.fqdn}. Upgrade today to access all fixed.net features.`,
            icon: `exclamation-circle`,
            type: `warning`,
            cta: { label: `Upgrade`, action: this.upgrade }
          };
        case "none-delegate":
          return {
            text: `There is no active subscription for this site. Please ask the site owner to upgrade this subscription to access all fixed.net features.`,
            icon: `exclamation-circle`,
            type: `warning`
          };
        case "active-owner":
        case "active-delegate":
        case "active-admin":
        case "active-agent":
          return {
            text: `${this.site.fqdn} has an active subscription which renews every ${this.termLength}; the next due date is ${this.dueDate}.`,
            icon: `check-circle`,
            type: `success`
          };
        case "overdue-owner":
        case "overdue-delegate":
        case "overdue-admin":
        case "overdue-agent":
          return {
            text: `${this.site.fqdn} has an overdue subscription. Payment was due on ${this.dueDate}.`,
            icon: `exclamation-triangle`,
            type: `warning`
          };
        case "cancelled-owner":
        case "cancelled-delegate":
        case "cancelled-admin":
        case "cancelled-agent":
          return {
            text: `The subscription for ${this.site.fqdn} has been cancelled and will be downgraded in ${this.daysRemaining} days.`,
            icon: `exclamation-triangle`,
            type: `danger`,
            cta: { label: `Reactivate`, action: this.reactivate }
          };
        case "lapsed-owner":
        case "lapsed-delegate":
        case "lapsed-admin":
        case "lapsed-agent":
          return {
            text: `The subscription for ${this.site.fqdn} has lapsed. Resubscribe today to access all the benefits of fixed.net.`,
            icon: `exclamation-triangle`,
            type: `warning`,
            cta: { label: `Upgrade`, action: this.upgrade }
          };
      }
    }
  },
  watch: {
    ["site.isSubscribed"]() {
      this.getSiteSubscription();
    }
  },
  created() {
    this.getSiteSubscription();
  },
  methods: {
    getSiteSubscription() {
      this.isLoading = true;
      this.$store
        .dispatch("billing/getSiteSubscription", this.siteId)
        .then(subscription => {
          this.$set(this, "contract", subscription.contract);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    upgrade() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: this.site.fqdn,
          siteId: this.siteId,
          userId: this.site.authorId
        }
      });
    },
    reactivate() {
      this.isProcessing = true;
      this.$store
        .dispatch("billing/reactivateContract", {
          contractId: this.siteId
        })
        .then(result => {
          this.$toast.open({
            message: `${result}`
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
#site-subscription {
  display: flex;
  align-items: center;
  min-height: 68px;
  @include mobile {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 1em;
    max-width: 500px;
    margin: auto;
  }
  @include tablet {
    justify-content: space-between;
    padding: 0.5em 1em;
  }
  > p {
    margin: 0.5em;
  }
}
</style>
